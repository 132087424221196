import { useEffect, useState } from 'react';
import { axiosInstance } from '../../../../../network/apis';
import { IPlanRuls } from '../types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import _ from 'lodash';
import { useFormikContext } from 'formik';

export default function PlansInCreate() {
  /* ------------------------------- Local State ------------------------------ */
  const [subscribedPlansInCreate, setSubscribedPlansInCreate] = useState<IPlanRuls[]>([]);
  const [displayed, setDisplayed] = useState<Array<number>>([0, 1]);
  const [itemsSelected, setItemsSelected] = useState<number[]>([]);
  /* -------------------------------- Functions ------------------------------- */
  const ToggleArray = (value: boolean, plan: any) => {
    if (value) {
      setFieldValue(`features[${plan.id}][value]`, plan.validation_type === 'Number' ? '5' : value);
      setItemsSelected((prev) => [...prev, plan.id]);
    }
    if (!value) {
      setFieldValue(`features[${plan.id}][value]`, undefined);
      const data = itemsSelected.filter((item) => item !== plan.id);
      setItemsSelected(data);
    }

    //TODO: This is a temporary solution to check if the 12th feature is selected, then select the 8th feature
    if (
      value &&
      (plan.name === 'الربط مع هيئة الزكاة و الضريبة' ||
        plan.name === 'Zatca integration' ||
        plan.ability === 'zatca')
    ) {
      // select E-invoicing programmatically
      ToggleArray(
        true,
        subscribedPlansInCreate
          .filter((item) => {
            return item.name === 'قسم الفواتير' || item.name === 'Invoice Module';
          })[0]
          .children?.filter((item) => {
            return (
              item.name === 'الفواتير الإلكترونية' ||
              item.name === 'E-invoicing' ||
              item.ability === 'e_invoicing'
            );
          })[0]
      );
      return;
    }
  };
  /* ---------------------------------- Hooks --------------------------------- */
  const formikProps = useFormikContext<any>();
  const { setFieldValue, values } = formikProps;
  const { locale } = useIntl();

  useEffect(() => {
    axiosInstance
      .get(`admin/plan-features`)
      .then((response) => {
        setSubscribedPlansInCreate(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className='accordion' id='accordionExample'>
        {subscribedPlansInCreate &&
          subscribedPlansInCreate.map((plan, index) => (
            <div className='accordion-item mt-5' key={index}>
              <h2
                className='accordion-header'
                id='headingOne'
                onClick={() => setDisplayed(() => _.xor([index], displayed))}
              >
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  <i className={`mx-3 far fa-arrow-${locale === 'ar' ? 'left' : 'right'}`}></i>
                  {plan.name}
                </button>
              </h2>
              <div
                id='collapseOne'
                className={clsx('accordion-collapse collapse', {
                  show: displayed.includes(index),
                })}
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body' key={plan.id}>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5 flex-grow-1'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onClick={(value: any) => {
                        ToggleArray(value.target.checked, plan);
                      }}
                    />
                    <span className='form-check-label'>{plan.name}</span>
                  </label>
                  {plan.validation_type === 'Number' && itemsSelected.includes(plan.id) && (
                    <div className='row gx-10 my-3 mx-1'>
                      <input
                        className='form-control form-control-sm b-radius-16 basic-input my-1'
                        placeholder={plan.name}
                        defaultValue={5}
                        onChange={(value) => {
                          setFieldValue(`features[${plan.id}][value]`, value.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>

                {/* __________Children__________ */}
                {plan.children &&
                  values.features[6] &&
                  plan.children.map((child) => {
                    return (
                      <div key={child.id} id={child.id.toString()}>
                        <div
                          id='collapseOne'
                          className={clsx('accordion-collapse collapse', {
                            show: displayed.includes(index),
                          })}
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body' key={child.id}>
                            <label className='form-check form-check-sm form-check-custom form-check-solid me-5 flex-grow-1'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                onClick={(value: any) => {
                                  ToggleArray(value.target.checked, child);
                                }}
                                checked={values.features[child.id]?.value}
                              />
                              <span className='form-check-label'>{child.name}</span>
                            </label>
                            {child.validation_type === 'Number' &&
                              itemsSelected.includes(child.id) && (
                                <input
                                  className='form-control form-control-sm b-radius-16 basic-input my-5'
                                  placeholder={child.name}
                                  defaultValue={5}
                                  onChange={(value) => {
                                    setFieldValue(
                                      `features[${child.id}][value]`,
                                      value.target.value
                                    );
                                  }}
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* __________Children__________ */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
