import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';
import { $t } from '../../../../_metronic/i18n/formatMessage';

const useSubscriptionPlansWrapperSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */
  const required = $t('This field is required');

  const SubscriptionPlansWrapperSchema = useMemo(
    () =>
      Yup.object().shape({
        name_en: Yup.string().required(required),
        name_ar: Yup.string().required(required),
        description_ar: Yup.string(),
        description_en: Yup.string(),

        commercial_description_ar: Yup.string(),
        commercial_description_en: Yup.string(),

        type: Yup.number().required(required),
        numberInput: Yup.number().typeError($t('Only number are allowed!')).nullable(),
        features: Yup.array().required(required),

        monthly_price: Yup.number().when(['type'], {
          is: (type: any) => {
            return +type === 1;
          },
          then: Yup.number()
            .integer($t('Only positive integer numbers are allowed!'))
            .positive($t('Only positive integer numbers are allowed!'))
            .typeError($t('Only positive integer numbers are allowed!'))
            .min(0, $t('Only positive integer numbers are allowed!'))
            .required(required),
          otherwise: Yup.number().nullable(),
        }),

        yearly_price: Yup.number().when(['type'], {
          is: (type: any) => {
            return +type === 1;
          },
          then: Yup.number()
            .integer($t('Only positive integer numbers are allowed!'))
            .positive($t('Only positive integer numbers are allowed!'))
            .typeError($t('Only positive integer numbers are allowed!'))
            .min(0, $t('Only positive integer numbers are allowed!'))
            .required(required),
          otherwise: Yup.number().nullable(),
        }),

        invoice_period: Yup.number().when(['type'], {
          is: (type: any) => {
            return +type === 3;
          },
          then: Yup.number()
            .integer($t('Only positive integer numbers are allowed!'))
            .positive($t('Only positive integer numbers are allowed!'))
            .typeError($t('Only positive integer numbers are allowed!'))
            .min(0, $t('Only positive integer numbers are allowed!'))
            .required(required),
          otherwise: Yup.number().nullable(),
        }),
      }),

    [formatMessage]
  );

  return {
    SubscriptionPlansWrapperSchema,
  };
};

export { useSubscriptionPlansWrapperSchema };
